import {
  Base64
} from 'js-base64'

import md5, {
  base64
} from 'js-md5'

/**
 * 数字存储大小格式化
 * @param {number} num 存储大小 单位：Byte
 * @param {number} digits 保留几位小数
 * @return {string} 2MB
 */
function toStorage(num, digits) {
  digits = digits || 2
  if (num < 1024) {
    return num + 'B'
  }
  num = (num * 1000 / 1024)
  const si = [{
      value: 1E18,
      symbol: 'E'
    },
    {
      value: 1E15,
      symbol: 'P'
    },
    {
      value: 1E12,
      symbol: 'T'
    },
    {
      value: 1E9,
      symbol: 'G'
    },
    {
      value: 1E6,
      symbol: 'M'
    },
    {
      value: 1E3,
      symbol: 'K'
    }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
        si[i].symbol + 'B'
    }
  }
}

/**
 * 图片大小校验
 * @param {file}
 * @param {number}
 */
function validImgUpload(file, size) {
  size = +size || 10240
  const isSizeOut = file.size / 1024 > size
  if (isSizeOut) {
    // Message.error('上传图片大小不能超过' + toStorage(size * 1024))
  }
  return !isSizeOut
}

/**
 * 创建唯一的字符串
 * @return {string}
 */
function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}
/**
 * 获取当前时间
 */
function getCurrentTime() {
  var date = new Date() // 当前时间
  var month = zeroFill(date.getMonth() + 1) // 月
  var day = zeroFill(date.getDate()) // 日
  var hour = zeroFill(date.getHours()) // 时
  var minute = zeroFill(date.getMinutes()) // 分
  var second = zeroFill(date.getSeconds()) // 秒
  // 当前时间
  var curTime = date.getFullYear() + '-' + month + '-' + day +
    ' ' + hour + ':' + minute + ':' + second
  return curTime
}

function zeroFill(i) {
  if (i >= 0 && i <= 9) {
    return '0' + i
  } else {
    return i
  }
}
/**
 *
 * @param {String} num
 * @returns 千位符逗号分割
 */
function numFormat(num) {
  var res = num.toString().replace(/\d+/, function(n) { // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function($1) {
      return $1 + ','
    })
  })
  return res
}
/**
 *
 * @param {Number} x
 * @returns 四舍五入保留两位小数
 */
function toDecimal2(x) {
  var k = 1 / (10 ** (2 + 1))
  var f = Math.round(x * 100 + k) / 100
  var s = f.toString()
  var rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length <= rs + 2) {
    s += '0'
  }
  return s
}
// 加密
function baseEncode(str) {
  const now = Date.now().toString()
  const md532 = md5(now)
  const md516 = md532.substr(8, 16)
  return Base64.encode(((Base64.encode(str)).slice(0, -4)) + md516 + ((Base64.encode(str)).slice(-4)))
}
// 解密
function baseDecode(str) {
  const str2 = Base64.decode(str)
  return JSON.parse(Base64.decode((str2.slice(0, -20)) + (str2.slice(-4))))
}
export default {
  toStorage,
  validImgUpload,
  createUniqueString,
  getCurrentTime,
  numFormat,
  toDecimal2,
  baseEncode,
  baseDecode
}
